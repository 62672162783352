<template>
  <Bg>
    <RouterView />
  </Bg>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>
