import NProgress from 'nprogress'

export function createProgressGuard(router) {
  router.beforeEach(async (_to, _from, next) => {
    if (!_to.query.redirect&&_from.query.redirect) {
      _to.query.redirect = _from.query.redirect
      next({..._to});
    }else{
      next();
      NProgress.start()
    }
  });
  router.afterEach(_to => {
    NProgress.done()
  });
}
