<template>
  <div class="h-full relative bg-content overflow-hidden" style="position: relative;">
    <div id="container"></div>
    <div class="absolute top-0 left-0 right-0 bottom-0">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
var SEPARATION = 100, AMOUNTX = 50, AMOUNTY = 50;
var container;
var camera, scene, renderer;
var particles, particle, count = 0;
var mouseX = -660, mouseY = -510;
var windowHalfX = window.innerWidth / 2;
var windowHalfY = window.innerHeight / 1;

function init() {
    container = document.createElement("div");
    container.id = "bg-canvas";
    document.getElementById("container").appendChild(container);
    camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
    camera.position.z = 1000; scene = new THREE.Scene();
    particles = new Array();
    var e = Math.PI * 2; var d = new THREE.ParticleCanvasMaterial({ color: "#bce3fc", program: function (f) { f.beginPath(); f.arc(0, 0, 1, 0, e, true); f.fill() } });
    var a = 0;
    for (var b = 0; b < AMOUNTX; b++) {
        for (var c = 0; c < AMOUNTY; c++) {
            particle = particles[a++] = new THREE.Particle(d);
            particle.position.x = b * SEPARATION - ((AMOUNTX * SEPARATION) / 2); particle.position.z = c * SEPARATION - ((AMOUNTY * SEPARATION) / 2);
            scene.add(particle)
        }
    }
    renderer = new THREE.CanvasRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    container.appendChild(renderer.domElement);
    document.addEventListener("mousemove", onDocumentMouseMove, false);
    window.addEventListener("resize", onWindowResize, false)
}
function onWindowResize() {
    windowHalfX = window.innerWidth / 2; windowHalfY = window.innerHeight / 2;
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix(); renderer.setSize(window.innerWidth, window.innerHeight)
}
function onDocumentMouseMove(a) {
    mouseX = a.clientX - windowHalfX; mouseY = a.clientY - windowHalfY
}
function onDocumentTouchStart(a) {
    if (a.touches.length === 1) {
        a.preventDefault();
        mouseX = a.touches[0].pageX - windowHalfX;
        mouseY = a.touches[0].pageY - windowHalfY
    }
}
function onDocumentTouchMove(a) {
    if (a.touches.length === 1) {
        a.preventDefault();
        mouseX = a.touches[0].pageX - windowHalfX;
        mouseY = a.touches[0].pageY - windowHalfY
    }
}
function animate() {
    requestAnimationFrame(animate);
    render()
}
function render() {
    camera.position.x += (mouseX - camera.position.x) * 0.05;
    camera.position.y += (-mouseY - camera.position.y) * 0.05;
    camera.lookAt(scene.position);
    var a = 0;
    for (var b = 0; b < AMOUNTX; b++) {
        for (var c = 0; c < AMOUNTY; c++) {
            particle = particles[a++];
            particle.position.y = (Math.sin((b + count) * 0.3) * 50) + (Math.sin((c + count) * 0.5) * 50);
            particle.scale.x = particle.scale.y = (Math.sin((b + count) * 0.3) + 1) * 2 + (Math.sin((c + count) * 0.5) + 1) * 2
        }
    }
    renderer.render(scene, camera);
    count += 0.08
};
onMounted(()=>{
  init();
animate();
})
</script>

<style lang="scss" scoped>
.bg-content{
  background: url("@/assets/img/bg.png") no-repeat top center;
  background-size: cover;
}
</style>
