import { createWebHistory, createWebHashHistory,createMemoryHistory, createRouter } from 'vue-router'
import {createProgressGuard} from "./progress"
const { VITE_ROUTER_HISTORY_MODE = 'history', VITE_BASE_URL } = import.meta.env;
import Layout from '@/logLayout'
const historyCreatorMap={
  hash: createWebHashHistory,
  history: createWebHistory,
  memory: createMemoryHistory
}
export const createBuiltinVueRoutes = [
  {
    path: '',
    component: Layout,
    redirect: '/login',
    children:[
      {
        path: '/login',
        component: () => import('@/views/login')
      },
      {
        path: '/forgetPassword',
        component: () => import('@/views/forgetPassword')
      },
      {
        path: '/register',
        component: () => import('@/views/register')
      }
    ]
  },
  {
    path: '/agreement',
    component: () => import('@/views/agreement')
  },
  {
    path: '/logoff',
    component: () => import('@/views/logoff')
  },
  {
    path: '/policy',
    component: () => import('@/views/policy')
  },
  {
    path: '/401',
    component: () => import('@/views/errorPages/401')
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import('@/views/errorPages/404')
  }
]
export const router = createRouter({
  history: historyCreatorMap[VITE_ROUTER_HISTORY_MODE](VITE_BASE_URL),
  routes: createBuiltinVueRoutes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

/** Setup Vue Router */
export async function setupRouter(app) {
  app.use(router);
  createProgressGuard(router);
  // router.isReady()是一个Promise对象，它用于等待路由初始化完成，特别是当路由参数被获取后。这个方法确保在路由参数已经准备好并且可以被安全访问之后再进行任何依赖于路由参数的操作，比如发起网络请求。
  await router.isReady();
}
